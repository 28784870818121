import { useCallback, useEffect, useState } from 'react'
import { CarProfile } from '@tmap-web-lib/remote-api-client/src/common/types/car-profile'
import { useGetCarProfileList, useUpdateCarProfile } from '../react-query'
import { useGetNewAccessKey } from './use-get-new-access-key'

interface Props {
  enabled?: boolean
}

function useCarProfile(props?: Props) {
  const [carProfile, setCarProfile] = useState<CarProfile | null>()

  const getNewAccessKey = useGetNewAccessKey()
  const { data: carProfileList, refetch: refetchCarProfileList,  status: carProfileListStatus } = useGetCarProfileList({ enabled: props?.enabled ?? true })
  const { mutate: updateCarProfile, status: updateCarProfileStatus } = useUpdateCarProfile()

  /**
   * 본인인증을 거치게 될 경우 AK가 변경될 수 있어 AK 갱신 후 api 조회
   */
  const handleOnResume = useCallback(async () => {
    await getNewAccessKey()
    await refetchCarProfileList()
  }, [getNewAccessKey, refetchCarProfileList])

  useEffect(() => {
    if (carProfileList) {
      const reverse = [...carProfileList.integrateCarInfos].reverse()
      const result = reverse.find((item) => item.tscoreYn === 'Y') || reverse.find((item) => !!item.extCarInfo)

      /**
       * 운전점수를 통하지 않고 차량프로필에서 바로 원부로 등록된 차량의 경우 원부로 등록된 리스트 마지막 차량을 운전점수 차량으로 업데이트
       */
      if (result && result.tscoreYn !== 'Y') {
        const { carProfileId, mainYn } = result
        updateCarProfile({ carProfileId, mainYn, tscoreYn: 'Y' })
      }
      setCarProfile(result || null)
    }
  }, [carProfileList, updateCarProfile])

  return {
    carProfileList,
    carProfile,
    updateCarProfile,
    refetchCarProfileList,
    handleOnResume,
    carProfileListStatus,
    updateCarProfileStatus,
  }
}

export { useCarProfile }
