import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getTermsAgreement_v2 } from '@tmap-web-lib/remote-api-client/frontman'
import { GetTermsAgreementResData } from '@tmap-web-lib/remote-api-client/src/frontman/apis/terms/types/index'

interface Props {
  termsType: string;
  termsAllowCode: string;
  enabled?: boolean;
}

function useGetTermsAgreement(props: Props) {
  const { termsType, termsAllowCode , enabled} = props

  return useQuery<GetTermsAgreementResData, AxiosError>([KEYS.TERMS_AGREEMENT(termsAllowCode)],
    ({ signal }) => getTermsAgreement_v2({ termsType, termsAllowCode }, { signal })
      .then(({ data }) => data),
    {
      enabled
    }
  )
}

export { useGetTermsAgreement }
