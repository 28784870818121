import { CarProfile } from '@tmap-web-lib/remote-api-client/frontman'
import { CodeListResData } from '@tmap-web-lib/remote-api-client/src/common'
import { useMemo } from 'react'

interface Props {
  carProfile?: CarProfile | null
  carOptionCodeList?: CodeListResData
}
function useGetInsurerInfo({ carProfile, carOptionCodeList }: Props) {
  return useMemo(() => {
    const insurer = carOptionCodeList?.values.find((item) => item.value === carProfile?.insurance?.insurer)
    let description: { iconUrl: string, emergencyNumber: string } | undefined
    if (insurer?.description) {
      description = JSON.parse(insurer.description)
    }
    return { insurer, description }
  }, [carProfile, carOptionCodeList])
}

export { useGetInsurerInfo }