import {
  BenefitCategoriesExposureAreasType
} from '@tmap-web-lib/remote-api-client/src/frontman/apis/driving-score/types/benefit-list'

function getBenefitDisplayType(areas: BenefitCategoriesExposureAreasType[], area: BenefitCategoriesExposureAreasType['area']) {
  const display = areas.find((item) => item.area === area)
  if (display && display.type !== 'NONE') {
    return `type_${display.type.toLowerCase()}`
  }
}

export { getBenefitDisplayType }
