import { useMutation } from '@tanstack/react-query'
import {
  getOuterCarInfoForRefetch,
  OuterCarInfoParam,
} from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import {
  OuterCarInfoRefetchResponseData
} from '@tmap-web-lib/remote-api-client/src/frontman/apis/car-profile/get-car-info-refetch'

function useGetOuterCarInfoForRefetch() {
  return useMutation<OuterCarInfoRefetchResponseData, AxiosError, OuterCarInfoParam>(
    (data) => getOuterCarInfoForRefetch(data).then(({ data }) => data),
  )
}

export { useGetOuterCarInfoForRefetch }
