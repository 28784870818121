import { ButtonTapHighlight, DialogOk } from '../../components'
import React, { ForwardedRef, forwardRef, useCallback } from 'react'
import { RedDotLog, useOpenService, useOpenServiceUrl } from '../../hooks'
import { useAccessibleDetailsConditions } from './hooks'
import { useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useSelector } from 'react-redux'
import { selectSettingsIsCarLifeTermsAgreed } from '../../store/settings'
import { formatIntegerKilometers } from '../../utils'
import { BenefitList as Benefit } from '@tmap-web-lib/remote-api-client/dist/types/frontman'
import { ReportsResponseData } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  data: Benefit
  categoryName: string
  drivingScoreData?: ReportsResponseData
  displayType?: string
  redDotLog?: RedDotLog
  handleTermsCheck?: () => void
  eventLog: () => TmapLogBuilder
}

const BenefitListItem = forwardRef((props: Props, ref: ForwardedRef<HTMLLIElement>) => {
  const { data,
    drivingScoreData,
    categoryName,
    displayType,
    redDotLog,
    handleTermsCheck,
    eventLog
  } = props
  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)


  const dialogManager = useDialogManager()
  const openService = useOpenService()
  const openServiceUrl = useOpenServiceUrl()

  const conditionsCheck = useAccessibleDetailsConditions({
    score: drivingScoreData?.score ?? 0,
    totalTripDistance: drivingScoreData?.totalMileage ?? 0,
    minimumScore: data.condition.minimumScore || 0,
    minimumTotalMileage: 500000
  })

  const showDetailCondition = useCallback((score: number, mileage: number, description: string) => {
    dialogManager.showDialog({
      component: DialogOk,
      props: {
        title: <>
          {score}점 이상, {formatIntegerKilometers(mileage)}km 이상 <br />운전을 해야 받는 혜택이에요<br />
          <p className="sub">{description}<br />안전운전하고 꼭 혜택 받아보세요💪</p>
        </>
      }
    })
  }, [dialogManager])

  const handleClick = useCallback(() => {
    let log = eventLog()
      .set('action_id', displayType === 'type_commerce_carousel_main_only' ? 'tap.benefit_item_benefit_explore' : 'tap.benefit_item')
      .add('customField', ['category_id', data.categoryId])
      .add('customField', ['category_title', categoryName])
      .add('customField', ['benefit_id', data.benefitId])
      .add('customField', ['benefit_title', data.benefitTitle])

    if (redDotLog) {
      log = log.add('customField', ['reddot', redDotLog || 'n'])
    }

    // 메인, 혜택 페이지에서는 필수약관동의 체크하지 않음
    // 상위 컴포넌트에 handleTermsCheck props가 없다면 약관동의를 체크하지 않는 것으로 간주하면 된다.
    // 메인에서는 필수약관을 체크하지만 handleTermsCheck props가 없기 때문에 체크하지 않는 것으로 본다.
    if (!isCarLifeTermsAgreed) {
      if (handleTermsCheck) {
        handleTermsCheck()
        return
      }
    }
    // 조건 미달 시 모달 오픈
    if (data.condition.minimumScore) {
      const { minimumScore } = data.condition
      const description = conditionsCheck()
      if (description) {
        log.add('customField', ['popup', 'Y']).send()
        showDetailCondition(minimumScore, 500000, description)
        return
      } else {
        log.add('customField', ['popup', 'N']).send()
      }
    } else {
      log.add('customField', ['popup', 'N']).send()
    }
    const landingUrl = data.option.link.landingUrl
    if (landingUrl.startsWith('tmap://life')) {
      if (landingUrl === 'tmap://life?pageid=benefit_detail') {
        const extra = { seq: data.benefitId }
        openServiceUrl(`${landingUrl}&extra=${encodeURIComponent(JSON.stringify(extra))}`)
      } else {
        openServiceUrl(landingUrl)
      }
    } else {
      openService(landingUrl)
    }
  }, [
    data,
    categoryName,
    displayType,
    isCarLifeTermsAgreed,
    conditionsCheck,
    showDetailCondition,
    redDotLog,
    openServiceUrl,
    openService,
    handleTermsCheck,
    eventLog,
  ])

  return (
    <li
      ref={ref}
      id={data.benefitId}
    >
      <ButtonTapHighlight
        className="benefit_list_item"
        style={{ backgroundColor: data.option.display.backgroundColor }}
        onClick={handleClick}
      >
        {displayType === 'type_card' ? (
          <img
            src={data.benefitImageUrl}
            className="benefit_list_item_img"
            alt=""
          />
        ) : (
          <>
            {data.benefitImageUrl && (
              <img
                src={data.benefitImageUrl}
                className="benefit_list_item_img"
                alt=""
              />
            )}
            <div className="benefit_list_item_info">
              {data.benefitName && (
                <em className="benefit_list_item_name">{data.benefitName}</em>
              )}
              <strong className="benefit_list_item_tit">{data.benefitTitle}</strong>
              {(displayType !== 'type_commerce_carousel_main_only') && data.benefitDescription && (
                <div className="benefit_list_item_desc">{data.benefitDescription}</div>
              )}
              {data.benefitPriceCondition && (
                <div className="benefit_list_item_condition">
                  {'isSpecialContract' in data.option.insurance && data.option.insurance.isSpecialContract &&
                    <em className='benefit_list_item_point'>TMAP특약 </em>}
                  {/* 어드민에서 inline style로 넣을수 있음 */}
                  <span dangerouslySetInnerHTML={{ __html: data.benefitPriceCondition }} />
                </div>
              )}
              {(displayType !== 'type_commerce_carousel_main_only') && data.benefitAdditionalInfo && (
                // 어드민에서 inline style로 넣을수 있음
                <div
                  className="benefit_list_item_additional"
                  dangerouslySetInnerHTML={{ __html: data.benefitAdditionalInfo}}
                />
              )}
            </div>
          </>
        )}
      </ButtonTapHighlight>
    </li>
  )
})

export { BenefitListItem }
