import { useQuery } from '@tanstack/react-query'
import { BenefitCategories } from '@tmap-web-lib/remote-api-client/src/frontman/apis/driving-score/types'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getBenefitCategories } from '@tmap-web-lib/remote-api-client/frontman'

interface Options {
  useErrorBoundary?: boolean
}

function useGetBenefitCategories(options?: Options) {
  return useQuery<BenefitCategories[], AxiosError>([KEYS.BENEFIT_CATEGORIES()],
    () => getBenefitCategories().then(({ data }) => data),
    {
      useErrorBoundary: options?.useErrorBoundary ?? true
    }
  )
}

export { useGetBenefitCategories }
