import { useQuery } from '@tanstack/react-query'
import { UserInfoData } from '@tmap-web-lib/remote-api-client/src/common'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getUserInfo } from '@tmap-web-lib/remote-api-client/frontman'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useGetUserInfo() {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<UserInfoData, AxiosError>([KEYS.USER_INFO()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<UserInfoData>('/heimdall/heimdall-rmi/api/user/$userKey')
        return data
      } else {
        const { data } = await getUserInfo()
        return data
      }
    },
  )
}

export { useGetUserInfo }
