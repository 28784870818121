import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { ButtonTapHighlight } from '../../components'
import { selectSettingsEuk } from '../../store/settings'
import { useOpenService, useOpenServiceUrl } from '../../hooks'
import debounce from 'lodash-es/debounce'
import { RedDotListData, TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useSelector } from 'react-redux'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { SectionCard, Icon } from '../../components'
import BenefitJson from '../../assets/images/lottie/benefit.json'
import { UserMessageResType } from '@tmap-web-lib/remote-api-client/src/frontman/apis/driving-score/types/user-info'
import { shuffle } from 'lodash-es'
import { useGetRedDot } from '../Driving/vehicle/hooks'

interface Props {
  userMessageData?: UserMessageResType
  redDotList?: RedDotListData[]
  eventLog: () => TmapLogBuilder
}

type MessageType = 'INSURANCE' | 'ENGINE_OIL' | 'TIRE' | string

function MainBenefitBanner(props: Props) {
  const { userMessageData, redDotList, eventLog } = props
  const euk = useSelector(selectSettingsEuk)
  const openServiceUrl = useOpenServiceUrl()
  const openService = useOpenService()
  const { isRedDot: isTireRedDot } = useGetRedDot({ list: redDotList, itemName: 'MYCAR_TIRE' })
  const { isRedDot: isEngineOilRedDot } = useGetRedDot({ list: redDotList, itemName: 'MYCAR_ENGINEOIL' })
  const [benefitBannerMessage, setBenefitBannerMessage] = useState('')
  const [bannerLink, setBannerLink] = useState('')
  const [messageType, setMessageType] = useState<MessageType>('')

  const callClickDebouncing = useMemo(() => debounce(() => {
    eventLog().set('action_id', 'tap.benefit').addCustomField('msg_type', messageType).send()
    TmapApp.recordEvent({ name: 'tap.tab_benefit', json: { euk: euk } })
    if (bannerLink.startsWith('tmap://life')) {
      openServiceUrl(bannerLink)
    } else {
      openService(bannerLink)
    }
  }, 500, {
    leading: true,
    trailing: false
  }), [eventLog, euk, openServiceUrl, openService, messageType, bannerLink])

  const handleClick = useCallback(() => {
    callClickDebouncing()
  }, [callClickDebouncing])

  const getBenefitMessages = useCallback(() => {
    switch (messageType) {
      case 'INSURANCE':
        setBenefitBannerMessage('내 자동차보험 혜택보기')
        setBannerLink('tmap://life?pageid=benefit')
        break
      case 'ENGINE_OIL' :
        setBenefitBannerMessage('엔진오일 교체 혜택보기')
        setBannerLink('tmap://life?pageid=benefit&extra=%7B%22seq%22%3A%22VEHICLE_SUPPLIES_OIL%22%7D')
        break
      case 'TIRE':
        setBenefitBannerMessage('내 타이어 교체 혜택보기')
        setBannerLink('tmap://life?pageid=benefit&extra=%7B%22seq%22%3A%22VEHICLE_SUPPLIES_TIRE%22%7D')
        break
    }
  },[messageType])

  useEffect(() => {
    if (userMessageData?.typeCode === 'INSURANCE_MONTH_PRIOR_TO_EXPIRATION' || userMessageData?.typeCode === 'INSURANCE_MONTH_OF_EXPIRATION') {
      setMessageType('INSURANCE')
    } else {
      if (isTireRedDot && isEngineOilRedDot) {
        setMessageType(shuffle(['ENGINE_OIL', 'TIRE'])[0])
      } else if (isTireRedDot && !isEngineOilRedDot) {
        setMessageType('TIRE')
      } else if (!isTireRedDot && isEngineOilRedDot) {
        setMessageType('ENGINE_OIL')
      } else {
        setMessageType('INSURANCE')
      }
    }
  }, [userMessageData, isTireRedDot, isEngineOilRedDot])

  useEffect(() => {
    getBenefitMessages()
  }, [getBenefitMessages])

  return (
    <SectionCard>
      <ButtonTapHighlight
        className="main_cta"
        onClick={handleClick}
      >
        <Player
          className="main_cta_icon"
          src={BenefitJson}
          keepLastFrame={true}
          autoplay
        />
        <p className="main_cta_cont">{benefitBannerMessage}</p>
        <Icon
          iconName={'IconArrowRight'}
          color={'--C-gray-400'}
          className={'main_cta_arrow'}
        />
      </ButtonTapHighlight>
    </SectionCard>
  )
}

export { MainBenefitBanner }
