import { useQuery } from '@tanstack/react-query'
import { ModelAverageScores } from '@tmap-web-lib/remote-api-client/src/frontman/apis/driving-score/types'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getModelAverageScores } from '@tmap-web-lib/remote-api-client/frontman'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useGetModelAverageScores() {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<ModelAverageScores, AxiosError>([KEYS.MODEL_AVERAGE_SCORES()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<ModelAverageScores>('/driving-score/report/model-average-scores')
        return data
      } else {
        const { data } = await getModelAverageScores()
        return data
      }
    },
    {
      refetchOnMount: 'always',
    },
  )
}

export { useGetModelAverageScores }
