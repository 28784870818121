import { BenefitListItem } from './BenefitListItem'
import { classNames } from '@tmap-web-lib/utils'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react'
import { usePageIdContext } from '../../providers/page-id-context'
import { debounce } from 'lodash-es'
import { useSelector } from 'react-redux'
import { selectHeaderHeight } from '../../store/settings'
import { BenefitListDataType } from './hooks'
import { RedDotLog } from '../../hooks'
import { ReportsResponseData } from '@tmap-web-lib/remote-api-client/frontman'

interface Props  extends PropsWithChildren<HTMLAttributes<HTMLUListElement>>{
  data: BenefitListDataType
  drivingScoreData?: ReportsResponseData
  displayType?: string
  isFirstConnection?: boolean
  className?: string
  redDotLog?: RedDotLog
  handleTermsCheck?: () => void
  eventLog: () => TmapLogBuilder
}

function BenefitList(props: Props) {
  const {
    data,
    drivingScoreData,
    displayType,
    isFirstConnection,
    className,
    redDotLog,
    handleTermsCheck,
    eventLog,
    ...attrs
  } = props
  const { categoryName, benefitItem} = data

  const headerHeight = useSelector(selectHeaderHeight)

  const pageIdContext = usePageIdContext()

  const refWrap = useRef<HTMLUListElement | null>(null)
  const ref = useRef<HTMLLIElement[]>([])

  const handleScroll = useMemo(() => debounce(() => {
    const scrollTo = pageIdContext.extraObject.scroll_to
    if (scrollTo) {
      const findRef = ref.current.find((ref) => ref.id === scrollTo)
      if (findRef && headerHeight) {
        const marginTop = window.getComputedStyle(findRef.children[0]).getPropertyValue('margin-top').replace('px', '')
        findRef.children[0].setAttribute('style', 'border: 2px solid var(--C-primary-400)')
        window.scrollTo({ behavior: 'smooth', top: findRef.getBoundingClientRect().y - (headerHeight + Number(marginTop)) })
      }
    }
  }, 0), [headerHeight, pageIdContext])

  const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
    if (!Array.isArray(entries)) return
    const last = ref.current.length - 1
    entries.forEach((entry) => {
      if (ref.current[last].id === entry.target.id) {
        handleScroll()
      }
    })
  }, [handleScroll])

  useEffect(() => {
    if ((!benefitItem && !ref.current) || !isFirstConnection) return
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => handleResize(entries))
    ref.current.forEach((el) => observer.observe(el))
    return () => observer.disconnect()
  }, [benefitItem, ref, isFirstConnection, handleResize])

  useEffect(() => {
    if (!data || !refWrap.current) return
    refWrap.current?.scrollTo(0, 0)
  }, [refWrap, data])

  return (
    <ul
      className={classNames('benefit_list', displayType, className)}
      ref={refWrap}
      {...attrs}
    >
      {benefitItem && benefitItem.map((item, index) => {
        return (
          <BenefitListItem
            key={item.benefitId}
            ref={(el) => ref.current[index] = el as HTMLLIElement}
            data={item}
            drivingScoreData={drivingScoreData}
            categoryName={categoryName}
            displayType={displayType}
            redDotLog={redDotLog}
            handleTermsCheck={handleTermsCheck}
            eventLog={eventLog}
          />
        )
      })}
    </ul>
  )
}

export { BenefitList }
