import React, { useCallback, useEffect, useState } from 'react'
import {
  bannerAutoplayOptions,
  TrackingValues,
  useAdsManager,
  useIntersectionObserver,
} from '../../hooks'
import { Swiper, SwiperSlide } from 'swiper/react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { ButtonTapHighlight } from '../../components'
import { useSelector } from 'react-redux'
import { selectSettingsIsCarLifeTermsAgreed } from '../../store/settings'
import isEmpty from 'lodash-es/isEmpty'
import { AdType } from '@tmap-web-lib/remote-api-client/src/frontman'
import { useGetAdsTracking } from '../../react-query'

interface Props {
  data: AdType[] | null
  isLoading: boolean
  handleTermsCheck: () => void
  eventLog: () => TmapLogBuilder
}

function MainAdvertisementBanner(props: Props) {
  const { data, isLoading, handleTermsCheck, eventLog } = props

  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)
  const [initialSlideActiveIndex, setInitialSlideActiveIndex] = useState<number | null>(null)
  const [slideActiveIndex, setSlideActiveIndex] = useState<number | null>(null)
  const [trackingUrl, setTrackingUrl] = useState('')
  const [vimpressionId, setVimpressionId] = useState('')
  const [materialId, setMaterialId] = useState('')
  const [orientation, setOrientation] = useState(1)
  const [isVimpValuesReady, setIsVimpValuesReady] = useState(false)

  const { initTrackingValues, handleBanner } = useAdsManager()
  const { ref, isView, top }  = useIntersectionObserver((entry, observer) => {
    observer.unobserve(entry.target)
  }, { rootMargin: '0px 0px -64px 0px' })

  const currentTop = ref && ref.current[0] && ref.current[0].getBoundingClientRect().top

  const { refetch: refetchAdsTracking } = useGetAdsTracking({
    enabled: isView && isVimpValuesReady && currentTop <= top,
    url: trackingUrl,
    orientation: orientation,
    materialId: materialId,
    vimpressionId: vimpressionId
  })

  const handleBannerClick = useCallback((ad: AdType) => {
    if (!isCarLifeTermsAgreed) {
      handleTermsCheck()
      return
    }
    handleBanner(ad, eventLog, 'tap.banner_rolling')
    if (trackingUrl.includes('click')) {
      refetchAdsTracking()
    }
  }, [isCarLifeTermsAgreed, handleTermsCheck, eventLog, refetchAdsTracking, trackingUrl, handleBanner])

  const setTrackingData = useCallback((data: TrackingValues) => {
    setTrackingUrl(data.trackingUrl)
    setOrientation(data.orientation)
    setVimpressionId(data.vimpressionId!)
    setMaterialId(data.materialId)
  }, [])

  const renderSwiperSlide = useCallback((ad: AdType, index: number) => {
    return (
      <SwiperSlide key={index}>
        <ButtonTapHighlight
          className="main_banner_item"
          disabled={!ad.landingUrl}
          onClick={() => {
            const trackingInfo = ad.trackingEventUrls.find(trackingInfo => trackingInfo.key === 'click')
            const material = ad?.materials[0]
            if (trackingInfo && material) {
              setTrackingUrl(trackingInfo.value)
              setMaterialId(material.id)
              handleBannerClick(ad)
            }
          }}
        >
          {ad.materials[0].url && (
            <img
              src={ad.materials[0].url}
              className="main_banner_item_img"
              alt=""
            />
          )}
        </ButtonTapHighlight>
      </SwiperSlide>
    )

  }, [handleBannerClick])


  const renderSwipe = useCallback(() => {
    if (!isLoading && data && data.length > 0) {
      return (
        <div ref={(el) => ref.current[0] = el as HTMLElement}>
          <Swiper
            autoHeight={true}
            slidesOffsetBefore={0}
            centeredSlides={true}
            slidesPerView={'auto'}
            updateOnWindowResize={true}
            resizeObserver={true}
            watchOverflow={false}
            autoplay={data.length > 1 ? bannerAutoplayOptions : false}
            loop={data.length > 1}
            touchRatio={data.length > 1 ? 1 : 0}
            onInit={(e) => {
              setInitialSlideActiveIndex(e.realIndex)
            }}
            onSlideChange={(e) => {
              if (isView) {
                initTrackingValues(data[e.realIndex]).then((data) => {
                  if (data) {
                    setTrackingData(data)
                  }
                })
                setSlideActiveIndex(e.realIndex)
              }
            }}
          >
            {data.map((ad, index) => {
              return ad && renderSwiperSlide(ad, index)
            })}
          </Swiper>
        </div>
      )
    }
  }, [data, isLoading, renderSwiperSlide, ref, initTrackingValues, isView, setTrackingData])

  useEffect(() => {
    if (trackingUrl && orientation && materialId && vimpressionId) {
      setIsVimpValuesReady(true)
    }
  }, [trackingUrl, orientation, materialId, vimpressionId])

  useEffect(() => {
    if (data) {
      if (data.length < 2) {
        initTrackingValues(data[0]).then((data) => {
          if (data) {
            setTrackingData(data)
          }
        })
      } else {
        if (initialSlideActiveIndex !== null) {
          initTrackingValues(data[initialSlideActiveIndex]).then((data) => {
            if (data) {
              setTrackingData(data)
              setInitialSlideActiveIndex(null)
            }
          })
        }
      }
    }

  },[data, initTrackingValues,setTrackingData, initialSlideActiveIndex])

  useEffect(() => {
    if (isView && isVimpValuesReady && top) {
      if (currentTop <= top) {
        refetchAdsTracking().then(() => {
          setIsVimpValuesReady(false)
        })
      }
    }
  }, [isView, isVimpValuesReady, refetchAdsTracking, ref, top, currentTop])


  useEffect(() => {
    if (slideActiveIndex === null) return
  }, [slideActiveIndex])

  if (isEmpty(data)) return null

  return (
    <div className="main_banner">
      {renderSwipe()}
    </div>
  )
}

export { MainAdvertisementBanner }
