import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DialogBackground, DialogCancelButton, DialogContent, DialogFooter, DialogOkButton } from './Elements'
import { DialogWithAnimationProps } from './types'
import { DialogOk } from './DialogOk'
import { useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'
import { classNames, getSearchParams } from '@tmap-web-lib/utils'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import isEmpty from 'lodash-es/isEmpty'
import { useLogBox, useOpenService } from '../../hooks'
import { clearError } from '../../store/app'
import { TERMS_LOG_PRESET, TERMS_REGISTER_VEHICLE_LOG_PRESET } from '../../hooks/logbox/preset'
import { PageIdSearchParams } from '../../providers/page-id-context'
import { useSelector } from 'react-redux'
import {
  selectSettingsEuk,
  selectSettingsIsCarLifeTermsAgreed,
} from '../../store/settings'
import { CSSTransition } from 'react-transition-group'
import { store } from '../../store'
import { useGetTermsAgreement, useGetTermsGroup } from '../../react-query'
import { TermsContents } from '../../pages/Etc'
import { useAgreeTerms, useGetTermsInfo, useHandleAllTermsChecked } from '../../pages/Etc/hook'
import { CARPROFILE_SCHEME } from '../../utils'
import { TermsAgreements } from '@tmap-web-lib/remote-api-client/src/frontman/apis/terms/types/termsType'

export type TermsContentType =
  'THIRD_PARTY_POLICY'
  | 'ADVERTISING_POLICY'
  | 'MARKETING_THIRD_PARTY_POLICY'
  | 'DRIVING_HISTORY_POLICY'
  | 'DATA_POLICY'
  | 'NONE'

export interface SubTermsInfoType extends  TermsInfoType {
  required: boolean
}

export type TermsInfoType = {
  sortNo: number
  seq: number
  parentTermsCode: string | null
  termsCode: string
  isAgreed: boolean
  title: string
  link: string
  logActionId: string
  subTermsList?: SubTermsInfoType[]
}

// 약관 조회시에는 UBS01 생략된 값으로 조회
export enum TermsSimpleCode {
  UTERMS_B2C_1 = 'UTERMS-B2C-1',
  UTERMS_B2C_5 =  'UTERMS-B2C-5',
  TTERMS_B2C_18 = 'TTERMS-B2C-18',
  UTERMS_B2C_8 = 'UTERMS-B2C-8',
  UTERMS_B2C_8_1 = 'UTERMS-B2C-8_1',
  UTERMS_B2C_8_2 = 'UTERMS-B2C-8_2',
  UTERMS_B2C_8_3 = 'UTERMS-B2C-8_3',
}

export const TERMS_URL = '/tmap/common/detail.jsp?appCode=TMAP&termsCode='

function DialogGeneralTerms(props: DialogWithAnimationProps) {
  const {
    wrapperClassName,
    title,
    onOk,
    onCancel,
    isDialogOpen,
  } = props
  const euk = useSelector(selectSettingsEuk)
  const dialogManager = useDialogManager()
  const openService = useOpenService()

  // 주행데이터 약관 동의 여부
  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)
  const ref = useRef(null)
  const backgroundRef = useRef(null)

  // 전체 동의 버튼 활성 여부
  const [allPoliciesChecked, setAllPoliciesChecked] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isCheckedList, setIsCheckedList] = useState<boolean[]>([])
  const [modalIsOpen, setModalIsOpen] = useState(true)
  const [isEnd, setIsEnd] = useState<boolean | null>(null)
  const [termsData, setTermsData] = useState<TermsAgreements[]>([])
  const [termsCodeList,setTermsCodeList] = useState('')

  const { data: termsGroupData } = useGetTermsGroup({ groupAppCode: 'TMAP', termsGroupCode: 'TMAP_TSCORE_V2' })
  const { data: termsAgreementData, isLoading: isTermsAgreementDataLoading } = useGetTermsAgreement({
    termsType: 'UBS01',
    termsAllowCode: termsCodeList,
    enabled: !!termsCodeList.length
  })

  const [termsEventLog, termsExposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: TERMS_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: TERMS_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })

  const [registerVehicleEventLog, registerVehicleExposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: TERMS_REGISTER_VEHICLE_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: TERMS_REGISTER_VEHICLE_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })

  const [eventLog] = useMemo(() => {
    if (isCarLifeTermsAgreed && !isDialogOpen) {
      return [registerVehicleEventLog]
    } else {
      return [termsEventLog]
    }
  }, [isCarLifeTermsAgreed, isDialogOpen, registerVehicleEventLog, termsEventLog])

  const termsInfo = useGetTermsInfo({
    termsGroupData,
    termsData,
  })

  const handleAllPoliciesClick = useHandleAllTermsChecked({
    allPoliciesChecked,
    setAllPoliciesChecked,
    setIsCheckedList,
    eventLog
  })

  const showErrorDialog = useCallback(() => {
    dialogManager.showDialog(
      {
        component: DialogOk,
        props: {
          title: <>일시적으로 서비스를<br/> 이용할 수 없습니다.<br/> <p className="sub">앱을 다시 실행해주세요.</p></>,
          onOk() {
            setModalIsOpen(false)
            store.dispatch(clearError())
          }
        },
      }
    )
  }, [dialogManager])

  const clickAgreeBtn = useAgreeTerms({
    termsData,
    termsGroupData,
    isDisabled,
    isCheckedList,
    eventLog,
    setModalIsOpen,
    showErrorDialog,
    onOk,
    isDialogOpen
  })

  const handleAgreeBtn = useCallback(async () => {
    clickAgreeBtn()
  }, [clickAgreeBtn])

  const renderTerms = useCallback(() => {
    if (termsInfo?.length) {
      return <TermsContents
        termsInfo={termsInfo}
        isCheckedList={isCheckedList}
        setIsCheckedList={setIsCheckedList}
        setAllPoliciesChecked={setAllPoliciesChecked}
        eventLog={eventLog}
      />
    }
  }, [termsInfo, isCheckedList, setIsCheckedList, setAllPoliciesChecked, eventLog])

  const handleRegisterClick = useCallback(() => {
    eventLog().set('action_id', 'tap.register_mycar').send()
    const extra = {
      serviceName: 'life',
      logbox: {
        type: 'tmap',
        origin: 'life'
      },
      action: {
        actionType: 'close'
      },
      pageType: [
        'manual-input-off'
      ]
    }
    onCancel?.()
    const url = `${CARPROFILE_SCHEME}?pageid=register_car_profile&extra=${encodeURIComponent(JSON.stringify(extra))}`
    openService(url)
  }, [eventLog, openService, onCancel])

  const displayTerms = useMemo(() => {
    return (
      <>
        <DialogContent
          title={title}
          content={
            <>
              {
                <button className={classNames('terms_checkbox_all', allPoliciesChecked ? 'checked' : '')} onClick={handleAllPoliciesClick}>
                  <span className={classNames('terms_checkbox_all_label', allPoliciesChecked ? 'checked' : '')}>
                    모두 동의합니다
                  </span>
                </button>
              }
              {renderTerms()}
            </>
          }
        />
        <DialogFooter
          okButton={
            <DialogOkButton
              className={isDisabled ? 'clickable-disabled' : ''}
              onClick={handleAgreeBtn}
            >
              다음
            </DialogOkButton>
          }
        />
      </>
    )
  }, [title, handleAllPoliciesClick, allPoliciesChecked, renderTerms, isDisabled, handleAgreeBtn])

  const displayVehicleRegistration = useMemo(() => {
    return (
      <>
        <DialogContent
          title={
            <div className="vehicle_register_title">
              이제 운전점수가 생성돼요<br/>
              내 차 등록하고 관리도 해보세요
            </div>
        }
          content={
            <>
              <div className="vehicle_register_cont_img">
                <img src={require('../../assets/images/img_carcare_item_tire.svg').default} width={40} alt=""/>
                <img src={require('../../assets/images/img_carcare_item_oil.svg').default} width={40} alt=""/>
                <img src={require('../../assets/images/img_carcare_item_repair.svg').default} width={40} alt=""/>
                <img src={require('../../assets/images/img_carcare_item_insure.svg').default} width={40} alt=""/>
              </div>
              <p className="vehicle_register_cont_desc">리콜정보, 차량용품 교체 시기 안내까지</p>
            </>
          }
        />
        <DialogFooter
          okButton={
            <DialogOkButton className="vehicle_register_btn" onClick={handleRegisterClick}>
              내차등록하기
            </DialogOkButton>
          }
          cancelButton={
            <DialogCancelButton className="vehicle_register_cancel" onClick={() => {
              eventLog().set('action_id', 'tap.close').send()
              onCancel?.()
            }}>
              괜찮아요
            </DialogCancelButton>
          }
        />
      </>
    )
  }, [handleRegisterClick, onCancel, eventLog])

  const displayContent = useMemo(() => {
    if (termsGroupData) {
      if (!isCarLifeTermsAgreed) {
        return displayTerms
      } else {
        if (isDialogOpen !== undefined) {
          if (!isDialogOpen) {
            return displayVehicleRegistration
          } else {
            return null
          }
        }
      }
    }
  }, [termsGroupData, isCarLifeTermsAgreed, displayTerms, displayVehicleRegistration, isDialogOpen])

  useEffect(() => {
    if (isCarLifeTermsAgreed && isDialogOpen) {
      setModalIsOpen(false)
    }
  }, [isCarLifeTermsAgreed, isDialogOpen, onOk])

  useEffect(() => {
    if (!isDialogOpen) {
      setIsEnd(false)
    } else {
      setIsEnd(true)
    }
  },[isDialogOpen])

  useEffect(() => {
    const searchParams = getSearchParams<PageIdSearchParams>()
    if (!isEmpty(searchParams) && searchParams.extra) {
      const extraObject = JSON.parse(searchParams.extra as string)
      const extraCustomFields = extraObject.logbox

      if (extraCustomFields) {
        Object.keys(extraCustomFields).forEach(key => {
          if (isCarLifeTermsAgreed) {
            TERMS_REGISTER_VEHICLE_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
          } else {
            TERMS_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
          }
        })
      }
    } else {
      if (isCarLifeTermsAgreed) {
        TERMS_REGISTER_VEHICLE_LOG_PRESET.CUSTOM_FIELDS.push(['type', 'tab'], ['origin', 'tab'])
      } else {
        TERMS_LOG_PRESET.CUSTOM_FIELDS.push(['type', 'tab'], ['origin', 'tab'])
      }
    }


    if (isCarLifeTermsAgreed && !isEnd) {
      registerVehicleExposeLog().send()
    } else {
      if (isEnd === null) {
        termsExposeLog().send()
        TmapApp.recordEvent({ name: 'drivingscore_Agreement', json: { euk: euk } })
      }
    }
  }, [isCarLifeTermsAgreed, isEnd, registerVehicleExposeLog, termsExposeLog, euk])

  useEffect(() => {
    if (termsGroupData) {
      const termsCodes = termsGroupData.termsAgreeList.filter(terms => !terms.parentTermsCode).map((terms) => terms.termsCode).join(',')
      setTermsCodeList(termsCodes)
    }
  }, [termsGroupData])


  useEffect(() => {
    if (termsAgreementData && termsAgreementData.termsAgreements) {
      setTermsData(termsAgreementData.termsAgreements)
    }
  }, [termsAgreementData, termsCodeList])

  useEffect(() => {
    if (termsGroupData) {
      setIsCheckedList(new Array(termsGroupData.termsAgreeList.length).fill(false))
    }
  },[termsGroupData])

  useEffect(() => {
    if (isCheckedList[0]) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [isCheckedList])

  if (isTermsAgreementDataLoading) return  null

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={modalIsOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground className={wrapperClassName} ref={backgroundRef}>
        <CSSTransition
          classNames="bottom-slide-up"
          unmountOnExit={true}
          timeout={200}
          in={modalIsOpen}
          nodeRef={ref}
          appear
        >
          <div ref={ref} className={classNames('popup_wrap', 'popup_bottom', 'popup_terms_form')}>
            <div className={classNames('popup_cont')}>
              {displayContent}
            </div>
          </div>
        </CSSTransition>
      </DialogBackground>
    </CSSTransition>
  )
}

export default DialogGeneralTerms
export { DialogGeneralTerms }
