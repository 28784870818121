import { CSSProperties, HTMLAttributes, PropsWithChildren, useMemo } from 'react'
import { classNames } from '@tmap-web-lib/utils'
import * as Icons from './icons'

/*
 * 컬러 변경이 가능한 아이콘만 사용
 * size 프로퍼티는 TMDS 권고 사이즈 이다.(extra-small: 12 / small: 16 / medium: 20 / large: 24 / extra-large: 28)
 * exceptionalSize 프로퍼티는 권고하지 않는 사이즈의 경우 사용한다.
 * color 프로퍼티는 아이콘 색상을 변경할 때 사용한다
 */
type Size = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLElement>> {
  iconName: keyof typeof Icons
  size?: Size
  exceptionalSize?: number
  color?: string
  styles?: CSSProperties
}

function Icon(props: Props) {
  const {
    iconName,
    size = 'small',
    exceptionalSize,
    color,
    className,
    styles,
    children,
    ...attrs
  } = props

  const renderExceptionalSize = useMemo(() => {
    return {
      width: `${exceptionalSize}px`,
      height: `${exceptionalSize}px`
    }
  }, [exceptionalSize])

  const background = useMemo(() => {
    const value = `url(${Icons[iconName]})`
    if (color) {
      return {
        maskImage: value,
        WebkitMaskImage: value,
        backgroundColor: `var(${color})`
      }
    } else {
      return {
        backgroundImage: value
      }
    }
  }, [iconName, color])

  return (
    <i
      className={classNames('tmds-icon', exceptionalSize ? undefined : size, className)}
      style={{
        ...renderExceptionalSize,
        ...background,
        ...styles
      }}
      {...attrs}
    >
      {children}
    </i>
  )
}

export { Icon }
