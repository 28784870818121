import { useQuery } from '@tanstack/react-query'
import {
  getTermsGroupDetail,
  TermsGroupDetailReqType,
  TermsGroupDetailResType
} from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'

function useGetTermsGroup(props: TermsGroupDetailReqType) {
  const { groupAppCode, termsGroupCode } = props
  return useQuery<TermsGroupDetailResType, AxiosError>([KEYS.TERMS_GROUP(termsGroupCode)],
    () => getTermsGroupDetail({ termsGroupCode, groupAppCode }).then(({ data }) => data)
  )

}

export { useGetTermsGroup }
