import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { RedDotLog, useOpenService, useOpenServiceUrl } from '../../hooks'
import { ButtonTapHighlight } from '../../components'
import ImgCarDefault from '../../assets/images/img_car_default.svg'
import { CarProfile } from '@tmap-web-lib/remote-api-client/src/common/types/car-profile'
import { MainMenuSet } from './MainMenuSet'
import { CodeListResData } from '@tmap-web-lib/remote-api-client/src/common'
import { RedDotListData } from '@tmap-web-lib/tmap-app-interface'
import { menuSetItems, MenuSetType, tmapDateStringToDateTime } from '../../utils'
import { DateTime } from 'luxon'
import { sortBy } from 'lodash-es'
import { Icon } from '../../components'

interface Props {
  carProfile: CarProfile
  redDotList?: RedDotListData[]
  carOptionCodeList?: CodeListResData
  isHideIntroBanner: boolean
  redDotLog: RedDotLog
  eventLog: () => TmapLogBuilder
}

function MainCarInfo(props: Props) {
  const {
    carProfile,
    redDotList,
    carOptionCodeList,
    isHideIntroBanner,
    redDotLog,
    eventLog
  } = props

  const [menuList, setMenuList] = useState<MenuSetType[]>()

  const openServiceUrl = useOpenServiceUrl()
  const openService = useOpenService()

  const maintenanceList = useMemo(() => {
    if (!menuList) return
    return menuList.filter((item) => item.isRedDot)
  }, [menuList])

  const maintenanceMsg = useMemo(() => {
    if (!maintenanceList) return
    const length = maintenanceList.length
    if (length === 1) {
      if (maintenanceList[0].id === 'MYCAR_RECALL') {
        return '신규 리콜 건 발생'
      } else {
        return `${maintenanceList[0].title} 점검 필요`
      }
    } else if (length > 1) {
      return `점검권장 ${length}건`
    } else {
      return null
    }
  }, [maintenanceList])

  const handleSettingClick = useCallback(() => {
    eventLog().set('action_id', 'tap.carChange').send()
    openServiceUrl('tmap://life?pageid=vehicle_setting')
  }, [openServiceUrl, eventLog])

  const handleErrorImg = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = ImgCarDefault
    e.currentTarget.classList.add('default')
  }, [])

  const handleSetMenuList = useCallback((menu: MenuSetType) => {
    if ((menu.id === 'MYCAR_ENGINEOIL')
      || (menu.id === 'MYCAR_TIRE')
      || (menu.id === 'MYCAR_AIRCON')
      || (menu.id === 'MYCAR_AIR_FILTER')
    ) {
      setMenuList((prev) => {
        if (!prev) return
        const list = prev.map((item) => {
          if (item.id === menu.id) {
            item.startDateTime = ''
            item.isRedDot = false
          }
          return item
        })
        return sortBy(list, ['startDateTime', 'order']).reverse()
      })
    } else if (menu.id === 'MYCAR_RECALL') {
      // 리콜 선택 시 메뉴에서 제거
      setMenuList((prev) => {
        if (!prev) return
        return prev.filter((item) => item.id !== 'MYCAR_RECALL')
      })
    }

    if (menu.link.startsWith('tmap://webview')) {
      openService(menu.link)
    } else {
      openServiceUrl(menu.link)
    }
  }, [openServiceUrl, openService])

  const handleDetailClick = useCallback(() => {
    if (redDotLog === null) return
    if (maintenanceList && maintenanceList.length === 1) {
      handleSetMenuList(maintenanceList[0])
    } else {
      eventLog().set('action_id', 'tap.carReport').addCustomField('reddot', redDotLog).send()
      openServiceUrl(`tmap://life?pageid=vehicle`)
    }
  }, [maintenanceList, redDotLog, handleSetMenuList, openServiceUrl, eventLog])

  useEffect(() => {
    // 유효한 레드닷 필터 후 최근등록일 순으로 정렬
    const list = redDotList?.filter((redDot) => (tmapDateStringToDateTime(redDot.expiredDateTime) > DateTime.now()) && redDot.item !== 'SCORE_REGISTERED')
    if (list) {
      menuSetItems.forEach((menu) => {
        if (menu.id !== 'MYCAR' && menu.id !== 'CARSALE') {
          menu.startDateTime = ""
          menu.isRedDot = false
        }
        list.forEach((redDot) => {
          if (menu.id === redDot.item) {
            menu.startDateTime = redDot.startDateTime
            menu.isRedDot = true
          }
        })
      })
    }
    // 리콜 레드닷이 없는 경우 메뉴에서 제거
    const filterList = menuSetItems.filter((menu) => {
      if (menu.id === 'MYCAR_RECALL' && !menu.isRedDot) return null
      return menu
    })
    // 전기차의 경우 엔진오일, 에어필터 메뉴 제외
    if (carProfile.fuel === 'FT_EV') {
      const result = filterList.filter((item) => (item.id !== 'MYCAR_ENGINEOIL') && (item.id !== 'MYCAR_AIR_FILTER'))
      setMenuList(sortBy(result, ['startDateTime', 'order']).reverse())
      return
    }
    setMenuList(list ? sortBy(filterList, ['startDateTime', 'order']).reverse() : filterList)
  }, [redDotList, carProfile])

  // 차량점검(레드닷)이 있는 경우 차량 이미지가 잠깐 보였다가 사라지는 현상이 있어 undefined를 체크 함.
  if (maintenanceMsg === undefined) return null

  return (
    <>
      <div className="main_vehicle">
        <ButtonTapHighlight
          className="main_vehicle_setting"
          isFullWidth={false}
          onClick={handleSettingClick}
        >
          {carProfile.carNumber}
          <Icon
            iconName={'IconArrowDown'}
            exceptionalSize={18}
            color={'--icon-quaternary'}
          />
        </ButtonTapHighlight>
        {maintenanceMsg ? (
          <ButtonTapHighlight
            className="main_vehicle_warning"
            isFullWidth={false}
            onClick={handleDetailClick}
          >
            <object
              data={require('@/assets/images/sedan.svg').default}
              width="104"
              height="172"
              aria-label={'자동차 이미지'}
            />
            <em className="main_vehicle_warning_msg">{maintenanceMsg}</em>
          </ButtonTapHighlight>
        ) : (
          <ButtonTapHighlight
            className="main_vehicle_detail"
            isFullWidth={false}
            onClick={handleDetailClick}
          >
            <img
              src={carProfile.extCarInfo?.imgUrl || ImgCarDefault}
              width="200"
              height="120"
              onError={(e) => handleErrorImg(e)}
              alt=""
            />
            <div className="main_vehicle_detail_ico">
              <Icon
                iconName={'IconSearch'}
                color={'--wb-white'}
              />
            </div>
          </ButtonTapHighlight>
        )}
      </div>
      <MainMenuSet
        menuList={menuList}
        carProfile={carProfile}
        carOptionCodeList={carOptionCodeList}
        isHideIntroBanner={isHideIntroBanner}
        redDotLog={redDotLog}
        handleSetMenuList={handleSetMenuList}
        eventLog={eventLog}
      />
    </>
  )
}

export { MainCarInfo }
