import { SectionCard } from '../../components'
import { UserRanks } from '@tmap-web-lib/remote-api-client/src/frontman'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MainRankInfo, RankInfo } from './MainRankInfo'
import { CARPROFILE_SCHEME } from '../../utils'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { gte } from 'semver'

interface Props {
  data: UserRanks | undefined
  eventLog: () => TmapLogBuilder
}

function MainRank({ data, eventLog }: Props) {
  const [isAvailableVersion, setIsAvailableVersion] = useState(false)

  const getDiffRank = useCallback((prev: number | null, current: number | null) => {
    if (!current) return null
    return !prev ? undefined : (prev - current)
  }, [])

  const area = useMemo(() => {
    if (!data) return null
    const { areaName, yesterdayUserRank, todayUserRank } = data.byArea
    const locationRankLink = 'tmap://life?pageid=driving_analysis&extra=%7b%22scroll_to%22%3a%22my_town_ranking%22%7d'

    const result: RankInfo = {
      type: 'LOCATION',
      label: areaName ?? '우리동네',
      rank: getDiffRank(yesterdayUserRank, todayUserRank),
      link: !!areaName ? locationRankLink : (isAvailableVersion ? 'tmap://bookmark?pageid=place' : 'tmap://home'),
      isActive: !!areaName,
    }
    return result
  }, [data, isAvailableVersion, getDiffRank])

  const car = useMemo(() => {
    if (!data) return null
    const { className, userRank, yesterdayUserRank } = data.byModel
    const extra = {
      serviceName: 'life',
      logbox: {
        type: 'tmap',
        origin: 'life'
      },
      action: {
        actionType: 'close'
      },
      pageType: [
        'manual-input-off'
      ]
    }
    const vehicleRankLink = 'tmap://life?pageid=driving_analysis&extra=%7b%22scroll_to%22%3a%22vehicle_driving_score%22%7d'
    const vehicleRegisterLink = `${CARPROFILE_SCHEME}?pageid=register_car_profile&extra=${encodeURIComponent(JSON.stringify(extra))}`
    const result: RankInfo = {
      type: 'CAR',
      label: className ?? '내 차종',
      rank: getDiffRank(yesterdayUserRank, userRank),
      link: !!className ? vehicleRankLink : vehicleRegisterLink,
      isActive: !!className,
    }
    return result
  }, [data, getDiffRank])

  useEffect(() => {
    if ((TmapApp.env.isAndroid && gte(TmapApp.env.appVersion, '10.2.0')) || (TmapApp.env.isIOS && gte(TmapApp.env.appVersion, '10.3.0'))) {
      setIsAvailableVersion(true)
    }
  },[])

  if (!data) return null

  return (
    <SectionCard title={'내 운전점수 랭킹'}>
      <div className="main_rank">
        <MainRankInfo
          data={car}
          eventLog={eventLog}
        />
        <MainRankInfo
          data={area}
          eventLog={eventLog}
        />
      </div>
    </SectionCard>
  )
}

export { MainRank }
