import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { BoardListDataType, getBoardList } from '@tmap-web-lib/remote-api-client/frontman'
import { BoardListParams } from '@tmap-web-lib/remote-api-client/src/frontman/apis/board/get-board-list'

interface Props extends BoardListParams{
  useErrorBoundary?: boolean
}

function useGetScoreContentsList(props: Props) {
  const { pageNo, pageSize, useErrorBoundary } = props

  return useQuery<BoardListDataType, AxiosError>([KEYS.SCORE_CONTENTS_LIST()],
    () => getBoardList(false,{ pageNo, pageSize, boardCode: 'BOARD-SCORE' })
      .then(({ data }) => data),
    {
      useErrorBoundary: useErrorBoundary ?? true, // 기본은 공통 에러를 사용하고 특정 페이지에서 부분 에러 처리가 필요하다면 false로 옵션 제공
    }
  )
}

export { useGetScoreContentsList }
