import { AdType } from '@tmap-web-lib/remote-api-client/src/frontman'
import { useCallback } from 'react'
import { useOpenService } from './use-open-service'
import { useOpenServiceUrl } from './use-open-service-url'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'

export interface TrackingValues {
  orientation: number;
  vimpressionId: string;
  trackingUrl: string;
  materialId: string;
}

function useAdsManager() {
  const openService = useOpenService()
  const openServiceUrl = useOpenServiceUrl()

  const initTrackingValues = useCallback(async (data: AdType) => {
    /*
    * 운전점수 메인 광고 배너와 인트로 팝업 배너는 가로모드 시 노출되지 않는 정책이고, 운전이력, 인기혜택은 세로 고정 화면이라 매번 getDisplayInfo를 조회할 필요 없음
    * orientation은 항상 1 ('portrait')
    */
    const orientation = 1
    const adItem = data
    let trackingUrl, materialId, vimpressionId = ''

    const trackingInfo = adItem.trackingEventUrls.find(trackingInfo => trackingInfo.key === 'vimp')
    const material = adItem.materials[0]

    if (trackingInfo && material) {
      await import('uuid').then(({ v4 }) => {
        if (v4()) {
          vimpressionId = v4()
        }
      })
      trackingUrl = trackingInfo.value
      materialId = material.id
      return {
        orientation, vimpressionId, trackingUrl, materialId
      } as TrackingValues
    }
  }, [])

  const handleBanner = useCallback((ad: AdType, eventLog: () => TmapLogBuilder, actionId: string) => {
    if (ad.landingUrl) {
      if (ad.landingUrl.startsWith('tmap://life')) {
        openServiceUrl(ad.landingUrl)
      } else {
        openService(ad.landingUrl)
      }
      eventLog().set('action_id', actionId).addCustomField('ad_code', ad.materials[0].id).send()
    }
  }, [openService, openServiceUrl])

  return {
    initTrackingValues,
    handleBanner
  }
}

export { useAdsManager }


