import { TermsAgreements } from '@tmap-web-lib/remote-api-client/src/frontman/apis/terms/types'
import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { updateTermsAgreement_v2 } from '@tmap-web-lib/remote-api-client/frontman'
import { trackErrorLogs } from '../../utils'

function useUpdateTermsAgreement() {
  return useMutation<AxiosResponse, AxiosError, Pick<TermsAgreements, 'allowCode' | 'allowYn' | 'allowTitle'>[]>({
    mutationFn: (termsAgreements) => updateTermsAgreement_v2({ termsAgreements }),
    onError: (e, variables) => {
      const { allowCode, allowYn, allowTitle} = variables[0]
      trackErrorLogs(e, 'tscore:error:updateTermsAgreement', { allowTitle, allowCode, allowYn })
    }
  })
}

export { useUpdateTermsAgreement }
