import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { registerCarMaintenance } from '@tmap-web-lib/remote-api-client/frontman'
import { DeepPartial, CarMaintenanceDataType } from '@tmap-web-lib/remote-api-client/src/frontman/apis/driving-score/types/car-maintenance'

function useRegisterCarMaintenance(carProfileId: string) {
  return useMutation<AxiosResponse, AxiosError,DeepPartial<Omit<DeepPartial<CarMaintenanceDataType>, 'createdAt' | 'updatedAt'>>> (
    (data) => registerCarMaintenance(carProfileId, data),
  )
}

export {useRegisterCarMaintenance}
