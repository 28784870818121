import { useMutation } from '@tanstack/react-query'
import { registerOuterCarWonbu } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { EscApiBody } from '@tmap-web-lib/remote-api-client/src/common/index'
import { RegisterCarWonbuParam } from '@tmap-web-lib/remote-api-client/src/frontman/apis/car-profile/register-car-wonbu'

function useRegisterOuterCarWonbu() {
  return useMutation<EscApiBody, AxiosError, RegisterCarWonbuParam>(
    (data) => registerOuterCarWonbu(data).then(({ data }) => data),
  )
}

export { useRegisterOuterCarWonbu }
