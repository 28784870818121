import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import {
  EventBannerResponseType,
} from '@tmap-web-lib/remote-api-client/src/frontman/apis/event/types'
import { getEventBannerList } from '@tmap-web-lib/remote-api-client/frontman'

interface Options {
  useErrorBoundary?: boolean
}

function useGetEventBannerList(options?: Options) {
  return useQuery<EventBannerResponseType, AxiosError>([KEYS.EVENT_BANNER_LIST()],
    () => getEventBannerList().then(({ data }) => data)
    , {
      staleTime: 1000,
      useErrorBoundary: options?.useErrorBoundary ?? true
    }
  )
}

export { useGetEventBannerList }
