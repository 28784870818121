import { Player } from '@lottiefiles/react-lottie-player'
import ArrowFlow from '../../assets/images/lottie/arrow_flow.json'
import { ButtonTapHighlight } from '../../components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { DateTime } from 'luxon'
import { store } from '../../store'
import { selectSettingsMediaFloatingButton, setMediaFloatingButton } from '../../store/settings'
import { useSelector } from 'react-redux'
import { classNames } from '@tmap-web-lib/utils'

interface Props {
  handleClick: () => void;
  isScroll: boolean;
  isBenefitPopularVisible: boolean;
}

function MainFloatingButton(props: Props) {
  const { handleClick, isScroll, isBenefitPopularVisible } = props
  const ref = useRef(null)
  const floatingButtonClicked = useSelector(selectSettingsMediaFloatingButton)
  const [isVisible, setIsVisible] = useState(false)

  const handleClickBtn = useCallback(() => {
    handleClick()
    setIsVisible(false)
    store.dispatch(setMediaFloatingButton(DateTime.now().toISO()))
  }, [handleClick])

  useEffect(() => {
    if (isScroll) {
      setIsVisible(false)
      store.dispatch(setMediaFloatingButton(DateTime.now().toISO()))
    }
  }, [isScroll])

  useEffect(() => {
    if (floatingButtonClicked) {
      const now = DateTime.now().toISO()
      const midnight = DateTime.fromISO(floatingButtonClicked).plus({ days: 1 }).startOf('day').toISO()

      setIsVisible(now > midnight)
    } else {
      setIsVisible(true)
    }
  }, [floatingButtonClicked])


  return (
    <CSSTransition
      classNames={'bottom-full-slide-up'}
      unmountOnExit={true}
      timeout={300}
      in={isVisible}
      nodeRef={ref}
      appear
    >
      <div className={classNames("main_floating", !isBenefitPopularVisible ? 'bottom-changed' : '')} ref={ref}>
        <ButtonTapHighlight
          className="main_floating_btn"
          isBeforePseudoElement
          onClick={handleClickBtn}
        >
          콘텐츠 보기
          <Player
            className="main_floating_btn_ico"
            src={ArrowFlow}
            loop={true}
            autoplay
          />
        </ButtonTapHighlight>
      </div>
    </CSSTransition>
  )
}

export { MainFloatingButton }