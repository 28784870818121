interface Props {
  checked: boolean
  handleChange: (checked: boolean) => void
}

function Switch(props: Props) {
  const { checked = true, handleChange } = props

  return (
    <label className="form_switch">
      <input
        type="checkbox"
        className="form_switch_checkbox"
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
      />
    </label>
  )
}

export { Switch }
