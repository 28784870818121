import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { LoadingFullIndicator, LoadingGlobalIndicatorPortal } from './index'
import { CSSTransition } from 'react-transition-group'
import debounce from 'lodash-es/debounce'
import { useIsFetching } from '@tanstack/react-query'
import { KEYS } from '../../react-query'
import { useSelector } from 'react-redux'
import { selectSettingsIsSkeleton } from '../../store/settings'

interface Props {
  useDelay: boolean;
  imageSrc: string;
  rootElementSelector: string;
}

interface IndicatorProps  {
  useDelay?: boolean;
  imageSrc: string;
}

const LoadingGlobalIndicator = memo(function LoadingGlobalIndicator(props: Props) {
  const { useDelay, imageSrc, rootElementSelector } = props

  const isSkeleton = useSelector(selectSettingsIsSkeleton)

  return (
    <LoadingGlobalIndicatorPortal rootElementSelector={rootElementSelector}>
      {!isSkeleton && <PromiseTrackingIndicator useDelay={useDelay} imageSrc={imageSrc}/>}
    </LoadingGlobalIndicatorPortal>
  )
})

const PromiseTrackingIndicator = memo(function PromiseTrackingIndicator(props: IndicatorProps) {
  const { useDelay = false } = props

  const isFetching = useIsFetching()
  const isUbpayFetching = useIsFetching({ queryKey: [KEYS.UNPAID_TOLLS()] }) // 내차관리 > 미납통행건수 조회 (부분 로딩 인디케이터 사용)
  const isUbpayListFetching = useIsFetching({queryKey: [KEYS.UNPAID_TOLLS_LIST()]}) // 미납통행료내역 (글로벌 로딩 인디케이터 사용)

  const [isWorking, setWorking] = useState(isFetching > 0 && (isUbpayFetching === 0 || isUbpayListFetching > 0))
  const changeDebounce = useMemo(
    () => debounce((isWorking: boolean) => {
      setWorking(isWorking)
    }, 200, { leading: !useDelay }),
    [useDelay],
  )

  useEffect(() => {
    changeDebounce(isFetching > 0 && (isUbpayFetching === 0 || isUbpayListFetching > 0))
    return () => changeDebounce.cancel()
  }, [changeDebounce, isFetching, isUbpayFetching, isUbpayListFetching])

  return <Indicator {...props} isShowing={isWorking}/>
})

export const Indicator = memo(function Indicator(props: { duration?: number, isShowing: boolean, imageSrc: string }) {
  const { duration = 200, isShowing, imageSrc } = props
  const ref = useRef(null)

  return (
    <CSSTransition
      classNames="fade-in"
      unmountOnExit={true}
      timeout={duration}
      in={isShowing}
      nodeRef={ref}
    >
      <LoadingFullIndicator
        imageSrc={imageSrc}
        ref={ref}
      />
    </CSSTransition>
  )
})

export { LoadingGlobalIndicator }
